import axios from 'axios';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '',
      hashChanged: false,
      newHash: ''
    }
  },
  methods: {
    initVersionCheck(url, frequency = 1000 * 60 * 2) {
      // Get current version
      this.checkVersion(url);
      // Set interval
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },
    async checkVersion(url) {
      try {
        const fileResponse = await axios.get(url);
        this.newHash = fileResponse.data.hash;
        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
      } catch {
        return
      }
    },
    hasHashChanged(currentHash, newHash) {
      if (currentHash === '') {
        this.currentHash = newHash
        return false
      }

      return currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload();
    }
  }
};