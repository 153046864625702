import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth, getToken } from "@/helpers/auth";

Vue.use(VueRouter)

const app_roles = ['Manufacturing.User','Manufacturing.Admin']

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue'),
    meta: { authorize: ['Manufacturing.User'] }
  },
  {
    path: '/manufacturing',
    name: 'ManufacturingHome',
    component: () => import('../views/manufacturing/root.vue'),
    meta: { authorize: ['Manufacturing.User'] }
  },
  {
    path: '/manufacturing/boxes',
    name: 'ManufacturingBoxes',
    component: () => import('../views/manufacturing/boxes.vue'),
    meta: { authorize: ['Manufacturing.User','Manufacturing.Admin'] }
  },
  {
    path: '/manufacturing/products',
    name: 'ManufacturingProducts',
    component: () => import('../views/manufacturing/products.vue'),
    meta: { authorize: ['Manufacturing.User','Manufacturing.Admin'] }
  },
  {
    path: '/manufacturing/tests',
    name: 'ManufacturingTests',
    component: () => import('../views/manufacturing/tests.vue'),
    meta: { authorize: ['Manufacturing.User','Manufacturing.Admin'] }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/settings.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing',
    name: 'ManufacturingSettings',
    component: () => import('../views/manufacturing/settings.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/products',
    name: 'SettingsManufacturingProducts',
    component: () => import('../views/manufacturing/settings.products.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/boxes',
    name: 'SettingsManufacturingBoxes',
    component: () => import('../views/manufacturing/settings.boxes.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/boxes/systemreferences',
    name: 'SettingsManufacturingBoxesSystemreferences',
    component: () => import('../views/manufacturing/settings.boxes.systemreferences.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/boxes/validations',
    name: 'SettingsManufacturingBoxesValidations',
    component: () => import('../views/manufacturing/settings.boxes.validations.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/boxes/print',
    name: 'SettingsManufacturingBoxesPrint',
    component: () => import('../views/manufacturing/settings.boxes.print.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/tests',
    name: 'SettingsManufacturingTests',
    component: () => import('../views/manufacturing/settings.tests.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/tests/:pipelineid',
    name: 'SettingsManufacturingTestsPipeline',
    component: () => import('../views/manufacturing/settings.tests.pipeline.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/settings/manufacturing/tests/:pipelineid/worker/:workerid',
    name: 'SettingsManufacturingTestsWorker',
    component: () => import('../views/manufacturing/settings.tests.worker.vue'),
    meta: { authorize: ['Manufacturing.Admin'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/logout.vue')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../views/forbidden.vue')
  },
  {
    path: '/applicationdenied',
    name: 'ApplicationDenied',
    component: () => import('../views/applicationdenied.vue')
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("../views/notfound.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  let account = auth.getAccount()
  let roles = []

  if (authorize) {
    if (!account) {
        // not logged in so redirect to login page with the return url
        return next({ path: '/login' });
    }

    getToken().then(response => {

      let payload = JSON.parse(atob(response.split('.')[1]))
      roles = payload ? payload.roles : [];
      
      // make sure we have any at all application membership access
      if(!roles || !roles.length) return next({ path: '/applicationdenied' });
      // Match roles against application roles to verify frontend access
      if (!app_roles.some(r=> roles.includes(r))) return next({ path: '/applicationdenied' });
      // check if route is restricted by role
      if (authorize.length && !authorize.filter(r=> roles.includes(r))) {
        // role not authorised so redirect to forbidden page
        return next({ path: '/forbidden' });
      } else {
        next();
      }

    })
    .catch(error => {
      return Promise.reject("error")
    })

  }

  next();
})

export default router