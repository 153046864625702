import { UserAgentApplication } from "msal";

const clientId = `${process.env.VUE_APP_AZURE_CLIENTID}`;
const tenantId = `${process.env.VUE_APP_AZURE_TENANTID}`;
const redirectUri = `${process.env.VUE_APP_AZURE_REDIRECTURL}`;

const config = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const tokenConfig = {
  scopes: [`api://${process.env.VUE_APP_AZURE_CLIENTID}/Api.Manufacturing`]
};

export const auth = new UserAgentApplication(config);

auth.handleRedirectCallback(error => {
  if (error) {
    console.error(error);
  }
});

export const getToken = () => {
  let account = auth.getAccount()
  if(account) {
    return auth
      .acquireTokenSilent(tokenConfig)
      .catch(() => auth.acquireTokenRedirect(tokenConfig))
      .then(response => response.accessToken);
  } else {
    return null
  }
}

export const hasRole = (roleArray) => {
  let account = auth.getAccount()
  if(account) {
    return auth
      .acquireTokenSilent(tokenConfig)
      .then(response => {
        let payload = JSON.parse(atob(response.accessToken.split('.')[1]))
        let roles = payload ? payload.roles : [];
        return roles
      })
      .then(roles => {
        return roleArray.some(r=> roles.includes(r))
      })
  } else {
    return []
  }
}

export const getRoles = () => {
  let account = auth.getAccount()
  if(account) {
    return auth
      .acquireTokenSilent(tokenConfig)
      .then(response => {
        let payload = JSON.parse(atob(response.accessToken.split('.')[1]))
        let roles = payload ? payload.roles : [];
        return roles
      })
  } else {
    return []
  }
}
