<template>

  <div id="app" class="w-100 h-100">

    <b-toast id="refresh-needed" variant="primary" solid no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Update available!</strong>
        </div>
      </template>
      <div>There is a new release available, refresh your browser to get the update!</div>
      <div class="text-right"><b-button v-on:click="updateVersion" size="sm" variant="outline-primary">Refresh</b-button></div>
    </b-toast>

    <b-container class="h-100 p-0">

      <b-navbar v-if="showNavigation" toggleable="lg" :type="nav_type" :variant="nav_color">
        <b-navbar-brand to="/" class="p-0"><b-img :src="logo" height="40"></b-img></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <b-navbar-nav>
            <b-nav-item-dropdown text="Manufacturing" left v-if="isUser">
              <b-dropdown-item to="/manufacturing/products"><span class="d-flex w-100 justify-content-between align-items-center">Products<b-icon-cpu></b-icon-cpu></span></b-dropdown-item>
              <b-dropdown-item to="/manufacturing/tests"><span class="d-flex w-100 justify-content-between align-items-center">Tests<b-icon-stoplights></b-icon-stoplights></span></b-dropdown-item>
              <b-dropdown-item to="/manufacturing/boxes"><span class="d-flex w-100 justify-content-between align-items-center">Boxes<b-icon-box-seam></b-icon-box-seam></span></b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right v-if="isAdmin" no-caret>
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item to="/settings/manufacturing"><span class="d-flex w-100 justify-content-between align-items-center text-muted small">Manufacturing</span></b-dropdown-item>
              <b-dropdown-item to="/settings/manufacturing/products"><span class="d-flex w-100 justify-content-between align-items-center">Products<b-icon-cpu></b-icon-cpu></span></b-dropdown-item>
              <b-dropdown-item to="/settings/manufacturing/tests"><span class="d-flex w-100 justify-content-between align-items-center">Tests<b-icon-stoplights></b-icon-stoplights></span></b-dropdown-item>
              <b-dropdown-item to="/settings/manufacturing/boxes"><span class="d-flex w-100 justify-content-between align-items-center">Boxes<b-icon-box-seam></b-icon-box-seam></span></b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right no-caret>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <b-icon-person-fill></b-icon-person-fill>
              </template>
              <b-dropdown-item to="/profile"><span class="d-flex w-100 justify-content-between align-items-center">Profile<b-icon-person></b-icon-person></span></b-dropdown-item>
              <b-dropdown-item to="/logout"><span class="d-flex w-100 justify-content-between align-items-center">Sign Out<b-icon-box-arrow-left></b-icon-box-arrow-left></span></b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <router-view />

    </b-container>
  </div>
</template>

<script>
import { auth, hasRole } from "./helpers/auth";
import { refreshPageMixin } from '@/mixins/refresh-page.mixin';

export default {
  name: 'app',
  data() {
    return {
      account: null,
      title: process.env.VUE_APP_TITLE,
      nav_color: process.env.VUE_APP_NAV_COLOR,
      nav_type: process.env.VUE_APP_NAV_TYPE,
      isAdmin: false,
      isUser: false,
      showNavigation: false,
      logo: require('./assets/logo.png')
    }
  },
  async mounted() {
    this.account = auth.getAccount();
    this.isAdmin = await hasRole(['Manufacturing.Admin'])
    this.isUser = await hasRole(['Manufacturing.User','Manufacturing.Admin'])
    this.setNavigation()

    let path = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/version.json`;
    this.initVersionCheck(path)
  },
  watch: {
    hashChanged() {
      this.$bvToast.show('refresh-needed')
    },
    '$route' (to, from) {
      // react to route changes...
      this.setNavigation()
    }
  },
  mixins: [refreshPageMixin],
  computed: {
  },
  methods: {
    setNavigation() {
      switch(this.$router.currentRoute.path) {
        case '/login':
        case '/logout': 
        case '/applicationdenied': {
          this.showNavigation = false
          break
        }
        case '/': {
          if(this.account) this.showNavigation = true; else this.showNavigation = false;
          break 
        }
        default: {
          this.showNavigation = true
        }
      }      
    },
    updateVersion() {
      this.reloadApp()
    },
  },
};
</script>

<style>

html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

#app {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

.breadcrumb {
  margin-top: 10px;
}
</style>
